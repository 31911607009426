import menu from "./components/menu"
import feedback from "./components/feedback"
// import pinSection from "./components/pinSection";
import sliderMedia from "./components/sliderMedia"
import sliderButton from "./components/sliderButton";
import scrollBar from "./components/scrollBar";
import articleNav from "./components/articleNav";

menu()
feedback()
// pinSection()
articleNav()
sliderMedia()
sliderButton()
scrollBar()
