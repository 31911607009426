import Swiper, {Navigation, EffectCards} from 'swiper';

Swiper.use([Navigation, EffectCards]);

const sliderMedia = () => {
  const slider = document.querySelector('.swiper-wrapper')
  const button = document.querySelector('.js-button')

  let swiper

  if (slider) {
    swiper = new Swiper('.js-swiper', {
      effect: "cards",
      loop: true,
      grabCursor: true,
      speed: 1000,
      cardsEffect: {
        rotate: false,
        perSlideOffset: 2,
        slideShadows: false,
      },
      navigation: {
        nextEl: '.slider__action',
      },
      breakpoints: {
        1440: {
          grabCursor: false,
          cardsEffect: {
            perSlideOffset: 8,
          },
          navigation: {
            nextEl: slider,
          },
        }
      }
    })

    swiper.on('slideChangeTransitionStart', () => {
      button.classList.add('is-pressed')
    })

    swiper.on('slideChangeTransitionEnd', () => {
      button.classList.remove('is-pressed')
    })

    return swiper
  }

  return swiper
}

export default sliderMedia;
