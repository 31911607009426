import gsap from 'gsap';

const sliderButton = () => {
  const slider = document.querySelector('.js-slider')
  const button = document.querySelector('.js-button')

  if (slider) {
    gsap.set(button, {xPercent: -50, yPercent: -50});

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };

    const xSet = gsap.quickSetter(button, "x", "px");
    const ySet = gsap.quickSetter(button, "y", "px");

    slider.addEventListener('mousemove', (e) => {
      mouse.x = e.x;
      mouse.y = e.y;
    })

    gsap.ticker.add(() => {
      pos.x += (mouse.x - pos.x)
      pos.y += (mouse.y - pos.y)
      xSet(pos.x);
      ySet(pos.y);
    });

    slider.addEventListener('mouseover', () => {
      button.classList.add('is-focused')
    })

    slider.addEventListener('mouseout', () => {
      button.classList.remove('is-focused')
    })
  }
}

export default sliderButton