const scrollBar = () => {
  const scrollBarElement = document.querySelector('.js-scroll-bar');

  if (scrollBarElement) {
    const updateLine = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;

      scrollBarElement.style.width = `${scrolled  }%`;
    }

    window.addEventListener('scroll', updateLine)
  }
}

export default scrollBar
