const articleNav = () => {
  const navBtn = document.querySelector('.js-article-nav-btn');
  const navList = document.querySelector('.article-aside-links__list');

  if (navBtn) {
    navBtn.addEventListener('click', () => {
      navList.classList.toggle('article-aside-links__list--open');
      navBtn.classList.toggle('article-aside-links__btn--open');
    })
  }
}

export default articleNav